import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment'

import { GiHeartBottle } from 'react-icons/gi';


// icons

import { FaEye, FaBan, FaRegStar } from 'react-icons/fa';
import { ImageObj } from '../../../assets/images/imgObj';
import { MdLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';

import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';


const StrikeDetails = () => {

  

    const [users, setUser] = useState([]);
    const [Count, setCount] = useState(1);
    const [indexPage, setIndexPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const LoadUser = useCallback(async (pageNumber) => {

        setLoading(false);
        await axios.get('https://jsonplaceholder.typicode.com/users')
            .then((res) => {
                console.log(res.data)
                setUser(res.data);
                setCount(res.data.id);
                setLoading(true);
            
            })

    }, [], [users, Count])


    const rightBtn = () => {
        const conent = document.querySelector('.nav-tabs');
        conent.scrollLeft += 1060;

    };

    const leftBtn = () => {
        const conent = document.querySelector('.nav-tabs');
        conent.scrollLeft -= 1060;

    };
   

  
    useEffect(() => {

        LoadUser()
    }, [LoadUser])


    return (
        <React.Fragment>
            <section className="user-profile">
                <div className="user-frofile-box">
                    <div className="box-image" style={{ backgroundImage: `url(${ImageObj.Couple2})` }}>

                    </div>
                    <div className="box-content flex-beetween">
                        <div className="profile-detail flex-beetween">
                            <div className="profile-img">
                                <img src={ImageObj.Couple} alt="profile-image" />
                            </div>
                            <div className="profile-name">
                                <h2>Mahi</h2>
                                <p>Mumbai,India <MdLocationOn /></p>
                            </div>
                        </div>

                        <div className="theme-btn  text-right">
                            <Link type="text" to="/strike" className="btn btn btn-theme-primary">Back</Link>

                        </div>

                    </div>
                </div>
            </section>
            <section className="profile-tabs tabs-css" >

            <div className="tabs-head">
                    <div className='tabs-part'>
                        <div className="left" onClick={() => leftBtn()}>
                            <FaAngleLeft />
                        </div>
                        <div className="right" onClick={() => rightBtn()}>

                            <FaAngleRight />
                        </div>
                    </div>
                    <Tabs
                        defaultActiveKey="strike"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 "
                    >
                        <Tab eventKey="strike" title="Strike Information">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>Strike Information</h2>
                                            </div>
                                            <div className="info-content height-auto">
                                                <div className="content-box">
                                                    <h3>Reported By  :- </h3>
                                                    <a href="#">Influencer</a>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Reported on :- </h3>
                                                    <p>Post</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Reported Content  :- </h3>
                                                    <a href="#">Influencer</a>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Description :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Reason :- </h3>
                                                    <p>N/a</p>
                                                </div>
                                                

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </Tab>
                      

                    </Tabs>

                </div>


            </section >
            {/* <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
            <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">..A.</div>
          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">..B.</div>
          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...C</div>
        </div> */}
        </React.Fragment >
    )
}
export default StrikeDetails;