import {USER_POST_ID} from '../types/type'

const intialState  = null


 const userIdPostReducer = (state = intialState, action)=>{
    switch(action.type){
      case USER_POST_ID: 
      return state = action.payload

        
        default:
        return state;
    }
  };

  export default userIdPostReducer

