export const  TOGGLE_VALUE = "TOGGLE_VALUE";
export const  TOGGLE_REMOVE = "TOGGLE_REMOVE";
export const  TOASTER = "TOASTER";
export const  TOASTERERR = "TOASTERERR";
export const  EVENTNAME = "EVENTNAME";
export const  USER_POST_ID = "USER_POST_ID";




