import logo from './logo.svg';
import './App.css';
import Routes from './routes/routes'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useSelector} from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const toggle = useSelector((state)=> state.toggleReducers)
  return (
    <div  className={toggle ? "App  rtl light-theme":"App rtl sidebar-mini light-theme" }>
      <Routes/>
      <ToastContainer/>
    </div>
  );
}

export default App;
