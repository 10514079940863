import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment'
import { ToastContainer, toast, } from "react-toastify";
import { useForm, Controller } from 'react-hook-form';
import { GiHeartBottle } from 'react-icons/gi';


// icons
import { FaSearch } from 'react-icons/fa';
import { FaEye, FaBan, FaRegStar } from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';

// import PORTNUMBER from '../../api/api'

import Pagination from "react-js-pagination";
// require("bootstrap/less/bootstrap.less");


// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom'

// react bootstrap
import { Modal, Button, Collapse } from 'react-bootstrap'


const RefferalCode = () => {
    // const PORT = PORTNUMBER.PORTNUMBER
    const Token = localStorage.getItem("token")

    const [open, setOpen] = useState(false);
    // modal active
    const [activeShow, setActiveShow] = useState(false);
    const [inActiveShow, setInActiveShow] = useState(false);
    const [statusId, setStatusId] = useState();
    // const [Loading, setLoading] = useState(false);


    const [users, setUser] = useState([]);
    const [Count, setCount] = useState(1);
    const [indexPage, setIndexPage] = useState(1);
    const [loading, setLoading] = useState(false);


    const [activePage, setActivePage] = useState()

    const [searchTerm, setSearchTerm] = useState("");

    const [filterData, setFilterData] = useState([]);


    const [filterVisibility, setFilterVisibility] = useState(true);



    const { register, control, handleSubmit, reset } = useForm();






    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)
    }

    const inActiveHandleClose = () => setInActiveShow(false);
    const inActiveHandleShow = (id) => {
        setInActiveShow(true);
        setStatusId(id)

    }

    const activeUser = async () => {
        await axios.post('https://jsonplaceholder.typicode.com/users', { userId: statusId, status: "active" }, { headers: { authorization: `Bearer ${Token}` } }).then((res, err) => {
            LoadUser()
            toast.success("User is activated successfully.")
            activeHandleClose()
        })
    }
    const inActiveUser = async () => {
        await axios.post('https://jsonplaceholder.typicode.com/users', { userId: statusId, status: "inactive" }, { headers: { authorization: `Bearer ${Token}` } }).then((res, err) => {
            LoadUser()
            toast.success("User is inactivated successfully.")
            inActiveHandleClose()
        })
    }
    const LoadUser = useCallback(async (pageNumber) => {

        setLoading(false);
        await axios.get('https://jsonplaceholder.typicode.com/users')
            .then((res) => {
                console.log(res.data)
                setUser(res.data);
                setCount(res.data.id);
                setLoading(true);
                setFilterVisibility(true)
            })


        reset()

    }, [Token, reset,], [users, Count])



    useEffect(() => {

        LoadUser()
    }, [LoadUser])






    // if (filterVisibility === true) {
    //     setCount(Count)
    // } else {
    //     setCount(filterCount)
    // }



    // Change page''



    const handlePageChange = (pageNumber) => {

        if (filterVisibility === true) {
            setActivePage(pageNumber);
            LoadUser(pageNumber)
            setIndexPage(pageNumber)
            setLoading(false);

        } else {
            setActivePage(pageNumber);
            sendPage(pageNumber)
            setIndexPage(pageNumber)
            setLoading(false);

        }

    }


    const sendPage = (pageNumber) => {
        axios.post('https://jsonplaceholder.typicode.com/users', { page: pageNumber }, { headers: { authorization: `Bearer ${Token}` } })
            .then(() => {
                setLoading(true);

            })
    }

    const onSubmit = async (data) => {
        data.startDate = (!data.startDate || data.startDate === null || data.startDate === undefined) ? "" : data.startDate;
        data.endDate = (!data.endDate || data.endDate === null || data.endDate === undefined) ? "" : data.endDate;
        setLoading(false);
        // sendPage()
        await axios.post('https://jsonplaceholder.typicode.com/users', data, { headers: { authorization: `Bearer ${Token}` } })
            .then((res) => {
                setCount(res.data.data.userCount)
                setFilterData(res.data.data.userData)
                setLoading(true);
                setFilterVisibility(false)

            })

    }



    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="heading-top">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6">
                                <div className="main-head">
                                    <h4>RefferalCode</h4>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="theme-btn top-head text-right">
                                    <Button onClick={() => setOpen(!open)}
                                        aria-controls="example-collapse-text"
                                        aria-expanded={open} className="btn btn-theme-primary">{!open ? "Show Filter" : "Hide Filter"}</Button>
                                    <Button className="btn btn-theme-primary ml-3" onClick={() => setActiveShow(true)}>Create Referral Code</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Collapse in={open}>
                    <div className="table-order-box box shadow" id="example-collapse-text">
                        <div className="tab-head">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <input type="text" {...register('Search', { required: false })} className="form-control" placeholder="Search" />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <select className="form-select form-control" {...register('status', { required: false })} aria-label="Default select example">
                                                <option className="disabled-value d-none" value=""  >Status</option>
                                                <option value="male">Used</option>
                                                <option value="female">Unused</option>
                                               
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group before-con">

                                            <Controller
                                                control={control}
                                                name="startDate"

                                                render={({ field }) => (
                                                    <DatePicker


                                                        placeholderText='Date From'
                                                        onChange={(date) => field.onChange(date)}
                                                        selected={field.value}



                                                    />
                                                )}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group before-con after">


                                            <Controller
                                                control={control}
                                                name="endDate"

                                                render={({ field }) => (

                                                    <DatePicker

                                                        dateFormat={"dd/mm/yyyy"}
                                                        placeholderText='Date To'
                                                        onChange={(date) => field.onChange(date)}

                                                        selected={field.value}




                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-12">
                                        <div className="theme-btn btn-group-right text-right">
                                            <button type="submit" className="btn btn btn-theme-primary">Search</button>
                                            <button type="reset" onClick={() => LoadUser()} className="btn btn btn-theme-primary">Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className="project-table">
                {/* <div className="form-search">
                    <form>
                        <div className="form-group icon-input top-search">
                            <input type="text" className="form-control" onChange={(event) => { setSearchTerm(event.target.value) }} placeholder="Search" />
                            <FaSearch />
                        </div>

                    </form>
                </div> */}
                <div className="table-order shadow">

                    <table>
                        <thead>
                            <tr>

                                <th scope="col">S.No</th>
                                <th>Email</th>
                                <th>Referral Code</th>
                                <th>Status</th>
                                <th>Created on</th>
                            </tr>
                        </thead>
                        <tbody>


                            {filterVisibility ? users && users.length ? users.filter((val) => {
                                const date = val.createdAt
                                if (searchTerm === "") {
                                    return val
                                } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else {
                                    return false
                                }


                            }).map((user, index) => (
                                <tr key={user._id}>

                                    <th className="s-no" scope="row">{50 * (indexPage - 1) + (index + 1)}</th>

                                    <td >
                                        {user.name}
                                    </td>
                                    <td>{user.email}</td>
                                    <td className=""><span className={`badge bg-success ${user.status}`}>{user.status}</span>
                                    </td>
                                    <td>{user.email}</td>

                                    <td>{moment(user.createdAt).format("DD/MM/YYYY")}</td>


                                </tr>

                            )) : <tr><td className="no-data">NO ANY USER FOUND</td></tr>
                                :
                                filterData && filterData.length ? filterData.filter((val) => {
                                    const date = val.createdAt
                                    if (searchTerm === "") {
                                        return val
                                    } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else {
                                        return false
                                    }


                                }).map((user, index) => (
                                    <tr key={user._id}>
                                        <th className="s-no" scope="row">{50 * (indexPage - 1) + (index + 1)}</th>
                                        <td >
                                            {user.name}
                                        </td>
                                        <td>{user.email}</td>
                                        <td>{user.mobile}</td>
                                        <td>{moment(user.createdAt).format("DD/MM/YYYY")}</td>
                                        <td className=""><span className={`badge bg-success ${user.status}`}>{user.status}</span>
                                        </td>
                                        <td className="action-top">
                                            {/* <Link to={`/editNewUser/${user._id}`} className="icons-action"><FaEdit /></Link> */}
                                            <Link to={`/userWineList/${user._id}`} className="icons-action"><FaEye /></Link>
                                            <Link to={`/userFavoriteList/${user._id}`} className="icons-action"><FaRegStar /></Link>
                                            {
                                                user.status === "inactive" ? <span onClick={() => activeHandleShow(user._id)} className="icons-action"><AiOutlineCheckCircle /></span> : <span onClick={() => inActiveHandleShow(user._id)} className="icons-action"><FaBan /></span>

                                            }


                                        </td>

                                    </tr>

                                )) : <tr ><td className="no-data">NO ANY USER FOUND</td></tr>}



                        </tbody>
                    </table  >
                    {
                        !loading ?
                            <div className="loader-wrapper">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <GiHeartBottle />
                                    </div>
                                </div>
                            </div> : ""
                    }


                    {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                </div>
                <div className='pagination'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={50}
                        totalItemsCount={Count}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                    />
                </div>

            </div>

            {/* modals */}

            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>
                <Modal.Body className="text-center">Create Referral Code</Modal.Body>
                <form className="model-form">
                    <div className="form-group">
                        <input type="email" className="form-control" placeholder="Email Address" />
                    </div>
                </form>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <button className="btn btn-theme-primary  btn-primary"  onClick={activeUser}>
                            Submit
                        </button>
                        <button className="btn btn-theme-primary  btn-primary"  onClick={activeHandleClose}>
                            Cancel
                        </button>
                    </div>
                   

                </Modal.Footer>
            </Modal>
            {/* active modal end */}

            {/* inactive modal */}
            <Modal className="status-modal" animation={false} show={inActiveShow} onHide={inActiveHandleClose}>
                <Modal.Body>Are you sure you want to inactivate user?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <button className="btn btn-theme-primary  btn-primary"  onClick={inActiveUser}>
                            Yes
                        </button>
                        <button className="btn btn-theme-primary  btn-primary"  onClick={inActiveHandleClose}>
                            No
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* inactive modal end */}
            {/* <ToastContainer /> */}
        </div>
    )
}
export default RefferalCode;
