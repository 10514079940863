import Dating from './thumbnails/dating.jpg'
import Couple2 from './banner/banner2.jpg'
import Couple3 from './banner/banner3.jpg'

import Avatar from '../images/avatar/Avatar1.png'
import GraphImage from '../images/banner/icon-2.png'
import BarImage from '../images/banner/icon-1.png'
import Couple from '../images/banner/banner1.jpg'
import LoveBanner from '../images/banner/banner.jpg'




export const ImageObj = {
    Dating:Dating,
    Couple2:Couple2,
    Couple3:Couple3,
    Avatar:Avatar,
    GraphImage:GraphImage,
    BarImage:BarImage,
    Couple:Couple,
    LoveBanner:LoveBanner

}