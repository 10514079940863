import {TOASTER} from '../types/type'





const intialState  = null

 const toastReducers = (state = intialState, action)=>{
    switch(action.type){
      case TOASTER: return state = action.payload
        default:
        return state;
    }
    
  };


  export default toastReducers

