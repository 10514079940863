
import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { ImageObj } from '../../../assets/images/imgObj';
import { MdLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Slider from "react-slick";
import axios from 'axios';

import { GiHeartBottle } from 'react-icons/gi';



const Profile = () => {

    const [defaultKey, setDefaultkey] = useState(false)
    console.log("kk", defaultKey)
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [users, setUser] = useState([]);
    const [Count, setCount] = useState(1);
    const [indexPage, setIndexPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const LoadUser = useCallback(async (pageNumber) => {

        setLoading(false);
        await axios.get('https://jsonplaceholder.typicode.com/users')
            .then((res) => {
                console.log(res.data)
                setUser(res.data);
                setCount(res.data.id);
                setLoading(true);

            })

    }, [], [users, Count])

    document.addEventListener("DOMContentLoaded", function () {
        var tabList = [].slice.call(document.querySelectorAll("#nav-tab button"));
        tabList.forEach(function (tab) {
            var tabTrigger = new document.getElementsByClassName("tab-pane");

            tab.addEventListener("click", function (event) {
                event.preventDefault();
                tabTrigger.addClassList("show");
            });
        });
    });

    useEffect(() => {

        LoadUser()
    }, [LoadUser])



    const sendRequest = useCallback(async () => {
        // don't send again while we are sending
        if (defaultKey) return
        // update state
        setDefaultkey(true)
        // send the actual request

        // once the request is sent, update state again
        setDefaultkey(false)
    }, [defaultKey]) // update the callback if the state changes

    return (
        <React.Fragment>

            <section className="profile-tabs tabs-css" onClick={sendRequest}>

                <Slider {...settings}>
                    <Tabs
                        defaultActiveKey="personalinfo"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 "
                    >
                        <Tab eventKey="personalinfo" title="Personal Info">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="info-box bg-box-one shadow mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>General Information</h2>
                                            </div>
                                            <div className="info-content">
                                                <div className="content-box">
                                                    <h3>UserName   :- </h3>
                                                    <p>N/A</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </Tab>
                        <Tab eventKey="updateprofile" title="Update Profile">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="info-box bg-box-one shadow mb-40">
                                            <form className="model-form">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="First Name" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Last Name" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="email" className="form-control" placeholder="Email" />
                                                </div>
                                                <div className="theme-btn  text-center">
                                                    <button type="submit" className="btn btn btn-theme-primary">Update Profile</button>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </Tab>
                        <Tab eventKey="changepassword" title="Change Password">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="info-box bg-box-one shadow mb-40">
                                            <form className="model-form">
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="New Password" />
                                                </div>
                                                <div className="form-group">
                                                    <input type="text" className="form-control" placeholder="Confirm Password" />
                                                </div>
                                               
                                                <div className="theme-btn  text-center">
                                                    <button type="submit" className="btn btn btn-theme-primary">Reset Password</button>

                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </Tab>
                    </Tabs>
                </Slider>


            </section >
            {/* <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
            <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">..A.</div>
          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">..B.</div>
          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...C</div>
        </div> */}
        </React.Fragment >
    )
}
export default Profile;