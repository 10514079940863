import {combineReducers} from 'redux'
import toggleReducers from './toggleReducers'
import toastReducers from './toastReducer'
import toastErrReducers from './toastErrReducers'
import eventNameReducers from './eventNameReducers'
import userIdPostReducer from './userIdPostReducer'




  const rootReducer = combineReducers({
    toggleReducers,
    toastReducers,
    toastErrReducers,
    eventNameReducers,
    userIdPostReducer
})

export default rootReducer