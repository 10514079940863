import React, { Component, Fragment, useState, useEffect } from "react";
import { withRouter, useHistory, Redirect, useLocation } from "react-router-dom";
import Login from "../auth/login/login";
import ResetPassword from "../auth/resetPasswordMail/resetPasswordMail";
import Header from "../header/header";
import { BrowserRouter, Switch, Route } from "react-router-dom";

const RequireAuth =({children}) =>  {
  const history = useHistory()
  const location = useLocation();
  console.log("location.pathname",location.pathname);
  console.log("children", children.props.path)
const [authTrue, setAuthTrue] = useState(false)
const [pathname, setPathname] = useState()
console.log("authTrue", authTrue)

  // state = { isAuthenticated: authTrue };
  const home = ()=>{
    history.push("/")

  }
  useEffect(() => {
    // if (!authTrue) {
    //   history.push("/");
    //   document.body.classList.remove('body-margin');
    // }

    if(location.pathname === "/" ){
      setPathname("/dashboard")
      
    }else{
      setPathname(location.pathname)
    }
     
    const Auth1 = localStorage.getItem("username");
    const Auth2 = localStorage.getItem("token");
  
    if (Auth1 === null && Auth2 === null ) {
      history.push("/")
      setAuthTrue(false)
    } else {
      setAuthTrue(true)
      document.body.classList.add('body-margin');


    }
  },[])

  // componentDidMount = () => {

  
  //   if (!authTrue) history.push("/");
  //   document.body.classList.remove('body-margin');


  // };

   const isAuthed = () =>{
    setAuthTrue(true)
    document.body.classList.add('body-margin');
    history.push("/dashboard")

  } 

  const unAuth = () => {
    document.body.classList.remove('body-margin');
    history.push("/")
    setAuthTrue(false)
  }

  return (
    authTrue === true ? (
   
         
      
      <Fragment>
        <Header unAuth={unAuth} />
        {children}
      </Fragment>
    ) : (
   
 
      <Switch>
      {/* <Redirect from={pathname} to="/" /> */}
        <Route exact path="/">
      <Login isAuthed={isAuthed} unAuth={unAuth} />
        </Route>
      {/* <Route exact path="/" component={Login}  /> */}

      <Route exact path="/resetpassword" component={ResetPassword}  />
      </Switch>
     

      
    )
  )
    }

export default withRouter(RequireAuth);
