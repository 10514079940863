import axios from 'axios'
import loginAPI from './api/api';

export const Login = async ({email,password}) => {
    const response = await axios.post(
       loginAPI.LOGIN_API, 
       { email, password }
    )
    return response;
}
