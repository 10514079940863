
const PORTNUMBER = process.env.REACT_APP_PORTNUMBER;


// const PORTNUMBER = "http://10.0.0.5:3009/admin";
// const PORTNUMBERMOBILE = "http://10.0.0.5:3002/mobile";





const API = {
  PORTNUMBER:PORTNUMBER,



  // LOGIN_API:`${PORTNUMBER}/adminLogin`,
  LOGIN_API:`${PORTNUMBER}/admin/login`,

  // PAYMENT GETWAY API MOBILE SCREEN


  // FORGETPASSWORD_API:`${PORTNUMBER}/forgetPassword`,
  FORGETPASSWORD_API:`${PORTNUMBER}/verifyUser`,
  SENDFORGOTPASSMAIL_API:`${PORTNUMBER}/password/forgot`,
  RESETPASSWORD_API:`${PORTNUMBER}/password/reset`,


  CHANGEPASSWORD_API:`${PORTNUMBER}/password/change

  `
}
export default API;