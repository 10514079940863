import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { ImageObj } from '../../../assets/images/imgObj';
import { MdLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';

import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';

const UserDetails = () => {


    const rightBtn = () => {
        const conent = document.querySelector('.nav-tabs');
        conent.scrollLeft += 1060;

    };

    const leftBtn = () => {
        const conent = document.querySelector('.nav-tabs');
        conent.scrollLeft -= 1060;

    };
   

    return (
        <React.Fragment>
            <section className="user-profile">
                <div className="user-frofile-box">
                    <div className="box-image" style={{ backgroundImage: `url(${ImageObj.Couple2})` }}>

                    </div>
                    <div className="box-content flex-beetween">
                        <div className="profile-detail flex-beetween">
                            <div className="profile-img">
                                <img src={ImageObj.Couple} alt="profile-image" />
                            </div>
                            <div className="profile-name">
                                <h2>Mahi</h2>
                                <p>Mumbai,India <MdLocationOn /></p>
                            </div>
                        </div>

                        <div className="theme-btn  text-right">
                            <Link type="text" to="/users" className="btn btn btn-theme-primary">Back</Link>

                        </div>

                    </div>
                </div>
            </section>
            <section className="profile-tabs tabs-css" >
                <div className="tabs-head">
                    <div className='tabs-part'>
                        <div className="left" onClick={() => leftBtn()}>
                            <FaAngleLeft />
                        </div>
                        <div className="right" onClick={() => rightBtn()}>

                            <FaAngleRight />
                        </div>
                    </div>
                    <Tabs
                        defaultActiveKey="personal"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 "
                    >
                        <Tab eventKey="personal" title="Personal Info">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>General Information</h2>
                                            </div>
                                            <div className="info-content">
                                                <div className="content-box">
                                                    <h3>Birthday :- </h3>
                                                    <p>06-03-1997 (24 years)</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>USD  :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Karma :- </h3>
                                                    <p>100</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Posts :- </h3>
                                                    <p>100</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Strikes  :- </h3>
                                                    <p>100</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Intoduction :- </h3>
                                                    <p>100</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Relationship Status :- </h3>
                                                    <p>100</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Open To :- </h3>
                                                    <p>100</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Relationship Model Preferences :- </h3>
                                                    <p>100</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Personal Bio :- </h3>
                                                    <p className="block-100">Location :- </p>
                                                    <p className="block-100">Ethnicity :- </p>
                                                    <p className="block-100">Exercise :- </p>
                                                    <p className="block-100">Drinking :- </p>
                                                    <p className="block-100">Smoking :- </p>
                                                    <p className="block-100">Pets :- </p>
                                                    <p className="block-100">Children :- </p>
                                                    <p className="block-100">Diet :- </p>
                                                    <p className="block-100">Politics :- </p>
                                                    <p className="block-100">Beliefs :- </p>
                                                    <p className="block-100">Vaccinations :- </p>
                                                    <p className="block-100">Cannabis :- </p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Gender Identity :- </h3>
                                                    <p className="block-100">Female-ness : 0</p>
                                                    <p className="block-100">Male-ness : 0</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Gender Expression :- </h3>
                                                    <p className="block-100">Feminity : 0</p>
                                                    <p className="block-100">Masculinity : 0</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Sex Assigned at Birth  :- </h3>
                                                    <p>Male</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Sexually attracted to :- </h3>
                                                    <p className="block-100">Women a/o Feminine : 0</p>
                                                    <p className="block-100">Men a/o Masculine : 0</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Romantically attracted to :- </h3>
                                                    <p className="block-100">Women a/o Feminine : 0</p>
                                                    <p className="block-100">Men a/o Masculine : 0</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Additional keywords describing my identity expression, Sexually :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>Travel Information</h2>
                                            </div>
                                            <div className="info-content">
                                                <div className="content-box">
                                                    <h3>Traveller :- </h3>
                                                    <p>xyz</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Travel Country :- </h3>
                                                    <p>India</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Travel Date :- </h3>
                                                    <p>09/20/2021</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>My Sexual Health</h2>
                                            </div>
                                            <div className="info-content">
                                                <div className="content-box">
                                                    <h3>Vaccination :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Last STD test  :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>HIV Status :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>My Kinks And Fetishes :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>Contact</h2>
                                            </div>
                                            <div className="info-content">
                                                <div className="content-box">
                                                    <h3>Mobile :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Email :- </h3>
                                                    <p>xyzabc@mailinator.com</p>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Tab>
                        <Tab eventKey="badges" title="Badges">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>Badges</h2>
                                            </div>
                                            <div className="info-content">
                                                <div className="row">
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Relationships</h3>
                                                            <div className="info round-box round-box-1">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Gender</h3>
                                                            <div className="info round-box round-box-2">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Sexuality</h3>
                                                            <div className="info round-box round-box-3">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Personal Health</h3>
                                                            <div className="info round-box round-box-4">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Community</h3>
                                                            <div className="info round-box round-box-5">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Others</h3>
                                                            <div className="info round-box round-box-1">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="photos" title="Photos" >
                            <div className="col-sm-12">

                                <div className="info-box mb-40">
                                    <div className="info-header btn-theme-primary">
                                        <h2>General Information</h2>
                                    </div>
                                    <Tabs
                                        defaultActiveKey="public"
                                        transition={false}
                                        id="noanim-tab-example"
                                        className="mb-3"
                                    >
                                        <Tab eventKey="public" title="Public">

                                            <div className="info-content height-auto">
                                                <div class="images-box-wine">
                                                    <div class="images-box min-height-box mt-0">
                                                        <div className="row row-cols-2 row-cols-lg-5 row-cols-md-3  g-2 ">
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </Tab>

                                        <Tab eventKey="connections" title="Connections">

                                            <div className="info-content height-auto">
                                                <div class="images-box-wine">
                                                    <div class="images-box min-height-box mt-0">
                                                        <div className="row row-cols-2 row-cols-lg-5 row-cols-md-3  g-2 ">
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab eventKey="Loved" title="Loved Once">

                                            <div className="info-content height-auto">
                                                <div class="images-box-wine">
                                                    <div class="images-box min-height-box mt-0">
                                                        <div className="row row-cols-2 row-cols-lg-5 row-cols-md-3  g-2 ">
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                            <div className="col ">
                                                                <div className="images-box ">
                                                                    <img src={ImageObj.Couple} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Tab>
                                    </Tabs>
                                </div>
                            </div>

                        </Tab>
                        <Tab eventKey="tribes" title="Tribes" >
                            <div className="col-sm-12">
                                <div className="info-box mb-40">
                                    <div className="info-header btn-theme-primary">
                                        <h2>My Tribes</h2>
                                    </div>
                                    <div className="info-content height-auto">
                                        <div className="row">
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="progress" title="Progress" >
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>General Information</h2>
                                            </div>
                                            <div className="info-content">
                                                <div className="row">
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Relationships</h3>
                                                            <div className="info round-box round-box-1">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Gender</h3>
                                                            <div className="info round-box round-box-2">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Sexuality</h3>
                                                            <div className="info round-box round-box-3">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Personal Health</h3>
                                                            <div className="info round-box round-box-4">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Community</h3>
                                                            <div className="info round-box round-box-5">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-md-4">
                                                        <div className="info-line b-0 new-circles badges">
                                                            <h3 className="title">Others</h3>
                                                            <div className="info round-box round-box-1">0
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="communities" title="Communities" >
                            <div className="col-sm-12">
                                <div className="info-box mb-40">
                                    <div className="info-header btn-theme-primary">
                                        <h2>My Communities</h2>
                                    </div>
                                    <div className="info-content height-auto">
                                        <div className="row">
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                  

                        <Tab eventKey="partner" title="Partner" >
                            <div className="col-sm-12">
                                <div className="info-box mb-40">
                                    <div className="info-header btn-theme-primary">
                                        <h2>My Partner</h2>
                                    </div>
                                    <div className="info-content height-auto">
                                        <div className="row">
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="posts" title="Posts" >
                            <div className="col-sm-12">
                                <div className="info-box mb-40">
                                    <div className="info-header btn-theme-primary">
                                        <h2>Posts</h2>
                                    </div>
                                    <div className="info-content height-auto">
                                        <div className="row">
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="events" title="Events" >
                            <div className="col-sm-12">
                                <div className="info-box mb-40">
                                    <div className="info-header btn-theme-primary">
                                        <h2>Events</h2>
                                    </div>
                                    <div className="info-content height-auto">
                                        <div className="row">
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-md-6">
                                                <div className="info-content detail-profile mb-20">
                                                    <div className="image-box">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                    <div className="box-user-con">
                                                        <div className="content-box">
                                                            <h3>Name :- </h3>
                                                            <Link to="#">Mahi</Link>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Level :- </h3>
                                                            <p>Star</p>
                                                        </div>
                                                        <div className="content-box">
                                                            <h3>Connection Type :- </h3>
                                                            <p>Follower</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
              

            </div>
        </section >
            {/* <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
            <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">..A.</div>
          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">..B.</div>
          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...C</div>
        </div> */}
        </React.Fragment >
    )
}
export default UserDetails;