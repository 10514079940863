import React, { useEffect, useCallback, useState } from 'react';
import './dashboard.scss'
import { Bar } from "react-chartjs-2";
import { MdModeComment, MdEmail, MdArticle, MdOutlineToday } from 'react-icons/md';
import Slider from "react-slick";

import { ImageObj } from '../../../assets/images/imgObj';

const Dashboard = () => {
    const [customerData, setCustomerData] = useState({});

    const settings = {
        dots: true,
        nav: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const customerGraph = useCallback(() => {

        // monthly Customers graph 
        let dataMonth = ["Jan", "Fab"];
        let dataValue = ["2", "2"];


        setCustomerData({
            labels: dataMonth,
            options: {
                legend: {
                    labels: {
                        filter: function (item, setCustomerData) {
                            // Logic to remove a particular legend item goes here
                            return !item.text.includes('label to remove');
                        }
                    }
                }
            },
            datasets: [
                {
                    label: "Customer",
                    data: dataValue,
                    fill: true,
                    backgroundColor: "rgba(75,192,192,0.2)",
                    borderColor: "rgba(75,192,192,1)"
                }
            ]
        })
    }, [])
    var options = {
        scales: {
            yAxes: [{
                ticks: {
                    precision: 0
                }
            }]
        }
    };

    useEffect(() => {
        customerGraph()
    }, [customerGraph])
    return (
        <React.Fragment>
            <section className="dashboard-section mb-40">

                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="tab-head box rounded shadow">
                                <div className="graph ">
                                    <div className="box-con mb-2 p-0">
                                        <p>Users</p>
                                    </div>
                                    <Bar options={options} data={customerData} />
                                    {/* <Chart/> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="couple-image inner-slide-nav">

                                <Slider {...settings}>
                                    <div className="item">
                                        <div className="item-box">
                                            <img src={ImageObj.Couple} alt="date couple image" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-box">
                                            <img src={ImageObj.Couple2} alt="date couple image" />
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-box">
                                            <img src={ImageObj.Couple3} alt="date couple image" />
                                        </div>
                                    </div>

                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="love-section mb-40" style={{backgroundImage:`url(${ImageObj.LoveBanner})`}}>
                <div className="love-heading">
                    <p></p>
                    <h3></h3>
                </div>
            </section>
            <section className="graph-manange">
                <div className="col-12">
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            <div className="box bg-primary-gradient rounded graph shadow">
                                <div className="box-graph flex-beetween">
                                    <div className="box-con">
                                        <h4>Posts</h4>
                                        {/* <h3>{wine.category && wine.category !== null ? wine.category['name']:'N/A'}</h3> */}
                                        <p>402</p>
                                    </div>
                                    <div className="box-icon">
                                        <MdModeComment />
                                    </div>
                                </div>
                                <div className="box-image">
                                    <img src={ImageObj.GraphImage} alt="graph icon" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="box bg-danger-gradient rounded graph shadow">
                                <div className="box-graph flex-beetween">
                                    <div className="box-con">
                                        <h4>Events</h4>
                                        {/* <h3>{wine.category && wine.category !== null ? wine.category['name']:'N/A'}</h3> */}
                                        <p>402</p>
                                    </div>
                                    <div className="box-icon">
                                        <MdOutlineToday />
                                    </div>
                                </div>
                                <div className="box-image">
                                    <img src={ImageObj.BarImage} alt="graph icon" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="box bg-success-gradient rounded graph shadow">
                                <div className="box-graph flex-beetween">
                                    <div className="box-con">
                                        <h4>Communities</h4>
                                        {/* <h3>{wine.category && wine.category !== null ? wine.category['name']:'N/A'}</h3> */}
                                        <p>40</p>
                                    </div>
                                    <div className="box-icon">
                                        <MdEmail />
                                    </div>
                                </div>
                                <div className="box-image">
                                    <img src={ImageObj.GraphImage} alt="graph icon" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-6">
                            <div className="box bg-warning-gradient rounded graph shadow">
                                <div className="box-graph flex-beetween">
                                    <div className="box-con">
                                        <h4>Articles</h4>
                                        {/* <h3>{wine.category && wine.category !== null ? wine.category['name']:'N/A'}</h3> */}
                                        <p>50</p>
                                    </div>
                                    <div className="box-icon">
                                        <MdArticle />
                                    </div>
                                </div>
                                <div className="box-image">
                                    <img src={ImageObj.BarImage} alt="graph icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Dashboard;