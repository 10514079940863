import {TOGGLE_VALUE,TOGGLE_REMOVE, TOASTER, TOASTERERR, EVENTNAME, USER_POST_ID} from '../types/type';



export const addToggle = (toggle) =>{
    return{
      type: TOGGLE_VALUE,
      payload: toggle,
    }
    
  }

  export const removeToggle = (toggle) =>{
    return{
      type: TOGGLE_REMOVE,
      payload: toggle,
    }
    
  }

  export const toasterValue = (toaster) =>{
    return{
      type: TOASTER,
      payload: toaster,
    }
  }

    export const toasterErrValue = (toasterErr) =>{
      return{
        type: TOASTERERR,
        payload: toasterErr,
      }
    
  }

  export const EventNameForImage = (eventName) =>{
    return{
      type: EVENTNAME,
      payload: eventName,
    }
  
}


export const SendUserIdPost = (userPostId) =>{
  return{
    type: USER_POST_ID,
    payload: userPostId,
  }

}