import React, { useEffect, useState } from 'react'
import './login.scss'
import {ImageObj} from '../../../assets/images/imgObj'

import { useHistory, useLocation, Redirect, Link } from 'react-router-dom'
import loginAPI from "../../../service/api/api";

import { useForm } from 'react-hook-form';
import axios from 'axios'

import { toasterValue } from '../../../redux/actions/action'
import { useDispatch } from 'react-redux';

import { FaEye, FaEyeSlash } from 'react-icons/fa';
import {Login} from '../../../service/index'
import { toast } from 'react-toastify';


export default ({ isAuthed, unAuth }) => {
  const dispatch = useDispatch()
  const [errLogin, setErrLogin] = useState();
  const [errPass, setErrPass] = useState();
  const [visibility, setVisibility] = useState(true)

  const history = useHistory();
  const location = useLocation();
  const { register, handleSubmit } = useForm();

  const pathname = location.pathname;

console.log("unAuth", unAuth)
  const pwVisibility = (value) => {
      setVisibility(value)
  }

  

  useEffect(() => {
      document.body.classList.add("remove-header");
      return () => {
          document.body.classList.remove("remove-header");
      }
  },[])


  useEffect(() => {
      const Auth1 = localStorage.getItem("username");
      const Auth2 = localStorage.getItem("token");

      if (Auth1 === null && Auth2 === null) {
           return unAuth()
      } else {
         return isAuthed()
      };
     
  },[])



  const onSubmit = (data) => {
     Login(data).then((res) => {
        if (res.data.status !== 200) {
            switch(res.data.message){
                case "Username Not Exist":
                setErrLogin(res.data.message)
                setErrPass("")
                break;
                case "Please Enter Correct Password":
                setErrPass(res.data.message)
                setErrLogin("")
                break;
                default: 
                setErrLogin("Technical Err")
            }

        } else {
            setErrLogin("")
            setErrPass("")
            isAuthed()
            localStorage.setItem("username", res.data.data.firstName);
            localStorage.setItem(
                "token",
                res.data.data.token
            );
          toast("You have logged in successfully.")
            // history.push({ pathname: "/dashboard", customdata: { pathname } })
        }
    })
    .catch(err => {
      setErrLogin(err.response.data.message);
    }) 
  }
  return(
    <section className="sign-auth-form" style={{backgroundImage: `url(${ImageObj.Dating})`}}>
    <div className="row">
      <div className="col-sm-6">
       
      </div>
      <div className="col-sm-6 p-0">
        <div className="login-top">
          <div className="login-form">
            <div className="form-field">
              <div className="head">
                <h3>Sign In</h3>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <input type="email" {...register('email', { required: true })} className="form-control"  placeholder="Enter email" />
                  <small id="emailHelp" className="form-text text-muted">{errLogin}</small>
                  
                </div>
                <div className="form-group">
                  <input type="password" className="form-control"  {...register('password', { required: true })}  placeholder="Password" />
                </div>
                <div className=" form-check">
                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                  <label className="form-check-label" htmlFor="exampleCheck1">Keep Me Logged In</label>
                </div>
                <div className="theme-btn">
                  <button type="submit"  className="btn btn-theme-primary btn btn-primary">Login</button>
                </div>
               <div className="frgt-pw">
                 <Link to="/resetpassword">Forgot your password?</Link>
               </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )
   
};
