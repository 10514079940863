import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "../components/pages/dashboard/dashboard";
import UserManagement from "../components/pages/users/users";
import Events from "../components/pages/events/events";
import EventsDetail from "../components/pages/events/eventsDetails";

import Posts from "../components/pages/posts/posts";
import PostDetails from "../components/pages/posts/postDetails";
import Strike from "../components/pages/strike/strike";
import StrikeDetails from "../components/pages/strike/strikeDetails";
import CommunityProfile from "../components/pages/communityProfile/communityProfile";
import PartnerProfileDetails from "../components/pages/partnerProfile/partnerProfileDetails";
import PartnerProfile from "../components/pages/partnerProfile/partnerProfile";
import CommunityProfileDetails from "../components/pages/communityProfile/communityProfileDetails";
import Articles from "../components/pages/article/article";
import ArticlesDetails from "../components/pages/article/articleDetails";
import UserDetails from "../components/pages/users/userDetails";
import Bugs from "../components/pages/bugs/bugs";
import BugsDetails from "../components/pages/bugs/bugsDetails";
import Features from "../components/pages/features/features";
import RefferalCode from "../components/pages/refferalCode/refferalCode";
import CommunityGuidelines from "../components/pages/communityGuidelines/communityGuidelines";
import CommunityGuidelinesDetails from "../components/pages/communityGuidelines/communityGuidelinesDetails";
import Notification from "../components/pages/notification/notification";
import NotificationDetails from "../components/pages/notification/notificationDetails";

import FeaturesDetails from "../components/pages/features/featuresDetails";
import InfluencerManagement from "../components/pages/influencerManagement/influencerManagement";
import InfluencerManagementDetails from "../components/pages/influencerManagement/influencerManagementDetails";

import Profile from "../components/pages/profile/profile";

import ResetPassword from "../components/auth/resetPasswordMail/resetPasswordMail";


import RequireAuth from "../components/requireAuth/requireAuth";




export default () => (
  <main className="main">
  <BrowserRouter>
    <RequireAuth>
      <Switch>
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/users" component={UserManagement} />
        <Route exact path="/posts" component={Posts} />
        <Route exact path="/eventsdetails/:id" component={EventsDetail} />
        <Route exact path="/events" component={Events} />

        <Route exact path="/strike" component={Strike} />
        <Route exact path="/strikedetails/:id" component={StrikeDetails} />
        <Route exact path="/communityprofile" component={CommunityProfile} />
        <Route exact path="/communityprofile/:id" component={CommunityProfileDetails} />
        <Route exact path="/bugs" component={Bugs} />
        <Route exact path="/bugs/:id" component={BugsDetails} />
        <Route exact path="/features" component={Features} />
        <Route exact path="/refferalcode" component={RefferalCode} />
        <Route exact path="/influencermanagement" component={InfluencerManagement} />
        <Route exact path="/influencermanagement/:id" component={InfluencerManagementDetails} />
        <Route exact path="/guidelines" component={CommunityGuidelines} />
        <Route exact path="/guidelines/:id" component={CommunityGuidelinesDetails} />
        <Route exact path="/notification" component={Notification} />
        <Route exact path="/notification/:id" component={NotificationDetails} />

        <Route exact path="/features/:id" component={FeaturesDetails} />
        <Route exact path="/partnerprofile" component={PartnerProfile} />
        <Route exact path="/partnerprofile/:id" component={PartnerProfileDetails} />
        <Route exact path="/articles" component={Articles} />
        <Route exact path="/articlesdetails/:id" component={ArticlesDetails} />

        <Route exact path="/userdetail/:id" component={UserDetails} />
        <Route exact path="/postdetails/:id" component={PostDetails} />
        <Route exact path="/profile" component={Profile} />






      </Switch>
    </RequireAuth>
    {/* <Switch>
      <ResetPassword/>
    </Switch> */}

  </BrowserRouter>
  </main>
);
