import React from "react";
import { NavLink, Link , useHistory} from "react-router-dom";
import { Dropdown } from 'react-bootstrap'
import { ImageObj } from "../../assets/images/imgObj";
import {MdDashboard, MdPerson, MdStorage, MdOutlineEvent, MdManageAccounts, Md3P, MdArticle, MdGroups, MdBugReport, MdFeaturedPlayList, MdOutlineQrCode2, MdStar, MdNotificationsActive} from 'react-icons/md'
import {RiGuideLine} from 'react-icons/ri'

import {useDispatch, useSelector} from 'react-redux'
import {addToggle, removeToggle} from '../../redux/actions/action'
import { toast } from 'react-toastify';

// The Header creates links that can be used to navigate
// between routes.

const Header = ({ unAuth }) => {
  const dispatch = useDispatch()
  const toggle = useSelector((state) => state.toggleReducers )
  const history = useHistory()
  const profile = ()=>{
    history.push("/profile")

  }
  const LogOut = () => {
   
    localStorage.removeItem("username");
    localStorage.removeItem(
        "token"
    );
    unAuth()
    // history.push("/")
   toast("You have logged out successfully.")

}
  return (
    <>
    <header className="top-header app-header">
      <div className="container-fluid">
        <div className="col-md-12 p-0">
          <div className="row">
            <div className="col-sm-6">
              <div className="top-search d-flex main-slide main-tog">
                
               {
                 toggle ? 
                 <div className="toggle-top" onClick={()=>dispatch(addToggle(false))}>
                    <div id="toggle" >
                      <div className="one" />
                      <div className="two" />
                      <div className="three" />
                    </div>
                  </div> :
                  <div className="toggle-top" onClick={()=>dispatch(removeToggle(true))}>
                  <div id="toggle" className="on">
                    <div className="one" />
                    <div className="two" />
                    <div className="three" />
                  </div>
                </div> 
               }
                  
                  <div className="logo-image">
               
                  <h2>Dating Admin Panel</h2>
                

                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="d-flex user-top">
                <p className="hover-white">Welcome Admin</p>

                <Dropdown className="theme-btn">
                  <Dropdown.Toggle className="btn" id="dropdown-basic">
                    <img className="dashboard-icon" src={ImageObj.Avatar} alt="side-img" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item  onClick={handleShow}>Update Profile</Dropdown.Item> */}
                    <Dropdown.Item onClick={profile}>Profile</Dropdown.Item>
                    <Dropdown.Item onClick={()=> LogOut()}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>
            </div>
          </div>
        </div>
      </div>

    </header>
    <aside className="app-sidebar">
     
          <div className="logo-name">
            <h1>Admin</h1>
          </div>
          <ul className="app-menu">
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/dashboard"><span><MdDashboard/></span>Home </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/users"><span><MdPerson/></span> Users </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/posts"><span><MdStorage/></span> Posts </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/events"><span><MdOutlineEvent/></span> Events </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/strike"><span><MdManageAccounts/></span> Strike Management </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/communityprofile"><span><Md3P/></span> Community Profile </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/articles"><span><MdArticle/></span> Articles </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/partnerprofile"><span><MdGroups/></span> Partner Profile </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/bugs"><span><MdBugReport/></span> Bugs </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/features"><span><MdFeaturedPlayList/></span> Features </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/refferalcode"><span><MdOutlineQrCode2/></span> Refferal Code </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/influencermanagement"><span><MdStar/></span> Influencer Management </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/guidelines"><span><RiGuideLine/></span> Community Guidelines </NavLink>
            </li>
            <li>
              <NavLink exact className="app-menu__item" activeClassName="active" to="/notification"><span><MdNotificationsActive/></span> Notification </NavLink>
            </li>
          </ul>
        
    </aside>
  </>
  )
};




export default Header;
