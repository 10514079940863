import React from "react";
import './login.scss'
import {ImageObj} from '../../../assets/images/imgObj'

export default ({ unAuth }) => (

    <section className="sign-auth-form" style={{backgroundImage: `url(${ImageObj.Dating})`}}>
        <div className="row">
          <div className="col-sm-6">
           
          </div>
          <div className="col-sm-6">
            <div className="login-top">
              <div className="login-form">
                <div className="form-field">
                  <div className="head">
                    <h3>Reset Password</h3>
                    <p>Please enter the email address you used when creating your account.</p>
                  </div>
                  <form>
                    <div className="form-group">
                      <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter ACCOUNT EMAIL ADDRESS" />
                      <small id="emailHelp" className="form-text text-muted">We'll never share
                        your email with anyone else.</small>
                    </div>
                    
                    <div className="theme-btn">
                      <button type="submit" className="btn">Send Request</button>
                    </div>
                   <div className="frgt-pw">
                     <a href="/">← Go back to sign-in</a>
                   </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
);


