import {EVENTNAME} from '../types/type'

const intialState  = null


 const eventNameReducers = (state = intialState, action)=>{
    switch(action.type){
      case EVENTNAME: return state = action.payload

        
        default:
        return state;
    }
  };

  export default eventNameReducers

