import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment'

import { GiHeartBottle } from 'react-icons/gi';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';


// icons

import { FaEye, FaBan, FaRegStar } from 'react-icons/fa';
import { ImageObj } from '../../../assets/images/imgObj';
import { MdLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import Slider from "react-slick";


const EventsDetails = () => {


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    const [users, setUser] = useState([]);
    const [Count, setCount] = useState(1);
    const [indexPage, setIndexPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const LoadUser = useCallback(async (pageNumber) => {

        setLoading(false);
        await axios.get('https://jsonplaceholder.typicode.com/users')
            .then((res) => {
                console.log(res.data)
                setUser(res.data);
                setCount(res.data.id);
                setLoading(true);

            })

    }, [], [users, Count])


    const rightBtn = () => {
        const conent = document.querySelector('.nav-tabs');
        conent.scrollLeft += 1060;

    };

    const leftBtn = () => {
        const conent = document.querySelector('.nav-tabs');
        conent.scrollLeft -= 1060;

    };

    useEffect(() => {

        LoadUser()
    }, [LoadUser])

    return (
        <React.Fragment>
            <section className="user-profile">
                <div className="user-frofile-box">
                    <div className="box-image" style={{ backgroundImage: `url(${ImageObj.Couple2})` }}>

                    </div>
                    <div className="box-content flex-beetween">
                        <div className="profile-detail flex-beetween">
                            <div className="profile-img">
                                <img src={ImageObj.Couple} alt="profile-image" />
                            </div>
                            <div className="profile-name">
                                <h2>Mahi</h2>
                                <p>Mumbai,India <MdLocationOn /></p>
                            </div>
                        </div>

                        <div className="theme-btn  text-right">
                            <Link type="text" to="/events" className="btn btn btn-theme-primary">Back</Link>

                        </div>

                    </div>
                </div>
            </section>
            <section className="profile-tabs tabs-css">
                <div className="tabs-head">
                    <div className='tabs-part'>
                        <div className="left" onClick={() => leftBtn()}>
                            <FaAngleLeft />
                        </div>
                        <div className="right" onClick={() => rightBtn()}>

                            <FaAngleRight />
                        </div>
                    </div>
                    <Tabs
                        defaultActiveKey="eventinfo"
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 "
                    >
                        <Tab eventKey="eventinfo" title="Event Info">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>General Information</h2>
                                            </div>
                                            <div className="info-content height-auto">
                                                <div className="content-box">
                                                    <h3>Introduction   :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Title :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Start Date & Time :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>End Date & Time :- </h3>
                                                    <a href="">Influencer</a>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Event Occurance :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Posted For :- </h3>
                                                    <p>N/A</p>
                                                </div>

                                                <div className="content-box">
                                                    <h3>Tags  :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Created By :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                                <div className="content-box">
                                                    <h3>Created On :- </h3>
                                                    <p>N/A</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </Tab>
                        <Tab eventKey="Photos" title="Photos">
                            <div className="info-box mb-40">
                                <div className="info-header btn-theme-primary">
                                    <h2>Post Images</h2>
                                </div>
                                <div className="info-content height-auto">
                                    <div class="images-box-wine">
                                        <div class="images-box min-height-box mt-0">
                                            <div className="row row-cols-2 row-cols-lg-5 row-cols-md-3  g-2 ">
                                                <div className="col ">
                                                    <div className="images-box ">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                </div>
                                                <div className="col ">
                                                    <div className="images-box ">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                </div>
                                                <div className="col ">
                                                    <div className="images-box ">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                </div>
                                                <div className="col ">
                                                    <div className="images-box ">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                </div>
                                                <div className="col ">
                                                    <div className="images-box ">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                </div>
                                                <div className="col ">
                                                    <div className="images-box ">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                </div>
                                                <div className="col ">
                                                    <div className="images-box ">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                </div>
                                                <div className="col ">
                                                    <div className="images-box ">
                                                        <img src={ImageObj.Couple} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </Tab>
                        <Tab eventKey="comments" title="Comments">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>Comments</h2>
                                            </div>
                                            <div className="info-content height-auto tab-table">
                                                <div className="table-order shadow">

                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S.No</th>
                                                                <th>Comments</th>
                                                                <th>Commented By</th>
                                                                <th>Commented On</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {users && users.length ? users.map((user, index) => (
                                                                <tr key={user._id}>

                                                                    <th className="s-no" scope="row">{index + 1}</th>

                                                                    <td >
                                                                        {"hello partner"}
                                                                    </td>
                                                                    <td><a href="">{"Neha Legend"}</a></td>
                                                                    <td>06-18-2021</td>



                                                                </tr>

                                                            )) : <tr><td className="no-data">NO ANY USER FOUND</td></tr>
                                                            }

                                                        </tbody>
                                                    </table  >
                                                    {
                                                        !loading ?
                                                            <div className="loader-wrapper">
                                                                <div className="loader">
                                                                    <div className="loading-svg">
                                                                        <GiHeartBottle />
                                                                    </div>
                                                                </div>
                                                            </div> : ""
                                                    }


                                                    {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>

                        <Tab eventKey="people" title="People">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="info-box mb-40">
                                            <div className="info-header btn-theme-primary">
                                                <h2>Peoples</h2>
                                            </div>
                                            <div className="info-content height-auto tab-table">
                                                <div className="table-order shadow">

                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S.No</th>
                                                                <th>	Name</th>
                                                                <th>Status</th>
                                                                <th>Commented On</th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>


                                                            {users && users.length ? users.map((user, index) => (
                                                                <tr key={user._id}>

                                                                    <th className="s-no" scope="row">{index + 1}</th>
                                                                    <td><a href="">{"Mahi"}</a></td>
                                                                    <td >
                                                                        {"Pending"}
                                                                    </td>

                                                                    <td>06-18-2021</td>



                                                                </tr>

                                                            )) : <tr><td className="no-data">NO ANY USER FOUND</td></tr>
                                                            }

                                                        </tbody>
                                                    </table  >
                                                    {
                                                        !loading ?
                                                            <div className="loader-wrapper">
                                                                <div className="loader">
                                                                    <div className="loading-svg">
                                                                        <GiHeartBottle />
                                                                    </div>
                                                                </div>
                                                            </div> : ""
                                                    }


                                                    {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                    </Tabs>

                </div>


            </section >
            {/* <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
            <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
            <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">..A.</div>
          <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">..B.</div>
          <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...C</div>
        </div> */}
        </React.Fragment >
    )
}
export default EventsDetails;