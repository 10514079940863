import {createStore} from "redux"
import rootReducer from '../redux/reducers/index'






  const store = createStore(rootReducer);


  export default store;