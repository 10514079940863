import {TOASTERERR} from '../types/type'





const intialState  = null





 const toastErrReducers = (state = intialState, action)=>{

    switch(action.type){
 
      case TOASTERERR: return state = action.payload

        default:
        return state;
    }
    
  };

  

export default toastErrReducers;


